import React, { useEffect } from 'react';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { useUserAuth } from '../providers/UserAuthContext';
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';
import Logo from '../assets/images/daylight-logo-long.png';
import DayLightLogo from '../resources/images/DayLightLogo';
import homeImage1 from '../assets/images/LoginImage.png';
import PlayStoreIcon from '../resources/icons/PlayStoreIcon';

const RedirectToAppStoreAndPlayStore = () => {
  const { redirectToAppStore, redirectToPlayStore } = useUserAuth();

  useEffect(() => {
    if (isIOS) redirectToAppStore();
    if (isAndroid) redirectToPlayStore();
    if (isAndroid) redirectToAppStore();
    if (isDesktop) redirectToAppStore();
  }, []);

  return (
    <section className="customer-portal">
      {/* <div className=""> */}
      <div className="flex justify-center items-start sm:flex-row flex-col sm:h-screen h-full">
        <div
          className={`!bg-primary sm:px-0 sm:py-[40px] p-[45px] text-center sm:w-1/2 w-full sm:h-full h-[390px] flex justify-around items-center flex-col`}
          style={{ padding: '0px' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <img
              className="max-w[790px] w-[100%]  mx-auto sm:h-[100vh] h-[390px] "
              style={{
                width: '100%'
                // height: '869px'
              }}
              src={homeImage1}
              alt=""
            />
          </div>
        </div>
        <div className="sm:w-1/2 sm:m-auto mt-[8px] mx-[25px]">
          <div className="sm:w-[85%] w-full mb-[260px] mx-auto">
            <div className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] ">
              <DayLightLogo />
            </div>
            <div>
              <h1 className="font-forma-djr-banner-regular text-[64px] leading-[72px] text-black my-8 mb-4 text-left">
                You’re in!
              </h1>
              <p className="font-forma-djr-deck-regular text-[17px] leading-[24px] opacity-[0.6] text-black my-2 mb-8 text-left">
                Download the Daylight app the start earning energy rewards, available on the App
                Store and Google Play Store.
              </p>
              <div className="flex sm:flex-row flex-col gap-[10px] sm:items-center items-left">
              <button
                  className="inline-flex items-center px-6 py-3 rounded-[8px] bg-primary_black text-secondary_white cursor-pointer sm:w-full md:w-[45%] border-secondary_grey border-2 hover:scale-105 hover:opacity-50"
                  onClick={redirectToAppStore}>
                  <FaApple className="text-5xl" />
                  <span className="flex flex-col items-start ml-4">
                    <span className="font-forma-djr-banner-regular leading-[10.75px] tracking-[1.5px] text-[15px]">Download on the</span>
                    <span className="font-semibold font-forma-djr-UI-regular title-font text-[25px]">App Store</span>
                  </span>
                </button>
                <button
                  className="inline-flex items-center px-6 py-3 rounded-[8px] bg-primary_black text-secondary_white cursor-pointer sm:w-full md:w-[50%] border-secondary_grey border-2 hover:scale-105 hover:opacity-50"
                  onClick={redirectToPlayStore}>
                  <PlayStoreIcon className="text-4xl" />
                  <span className="flex flex-col items-start ml-4">
                    <span className="font-forma-djr-banner-regular leading-[10.75px] tracking-[1.5px]  text-[15px]">GET IT ON</span>
                    <span className="font-semibold font-forma-djr-UI-regular title-font text-[25px]">Google Play</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RedirectToAppStoreAndPlayStore;
